// body {
//   margin: 0;
//   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
//     'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
//     sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   background-color: #ffffff !important;
//   color: #333333;
//   font-size: 14px;
//   height: 100%;
// }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.search-highlighting {
  background-color: #ffc53d;
  padding: 0;
}

.text-align-center {
  text-align: center;
}

.ant-divider-horizontal.ant-divider-with-text::before,
.ant-divider-horizontal.ant-divider-with-text::after {
  border-color: rgba(0, 0, 0, 0.06) !important;
}

.StripeElement {
  border: 1px solid #0FBFC4;
  border-radius: 4px;
  padding: 8px;
  color: #0FBFC4;
}

.StripeElement--invalid {
  border-color: #d7183f;
}

.ant-input-textarea-show-count::after {
  font-size: 11px;
}

// tr {
//   .ant-table-cell {
//     font-size: 12px;
//   }
// }

.resize-handler {
  width: 5px !important;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.rich-editor {

  .w-e-toolbar p,
  .w-e-text-container p,
  .w-e-menu-panel p {
    font-size: 14px !important;
  }
}

hr {
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}

.success-button {
  .ant-btn-primary {
    border-color: #52c41a;
    background-color: #52c41a;

    &:hover,
    &:focus {
      border-color: #52c41a;
      background-color: #52c41a;
      filter: brightness(1.1);
    }

    &:active {
      border-color: #52c41a;
      background-color: #52c41a;
      filter: brightness(0.9);
    }
  }
}

.modal-hide-footer {
  .ant-modal-confirm-btns {
    display: none;
  }
}

.menu-modal {
  margin: 0;
}

.ant-form-item-control-input:has(.description-field-control) {
  display: none;
}

.ant-form-item-control:has(.description-field-control) {
  .ant-form-item-control-input {
    display: none;
  }

  .ant-form-item-explain {
    color: #4B5B76;
  }
}

.tox-tinymce {
  border-width: 1px !important;
  border-radius: 4px !important;
}

.global-notification-bar {

  .ant-message-notice-content {
    padding: 1rem;
    background-color: #fffdeb;
    border: 1px solid #ffec99;
  }

  .ant-message-custom-content {
    // color: #FFFFFF;
  }
}

.ant-modal-confirm-content {
  max-width: 100% !important;
}

.ant-pro-card-header {
  .ant-pro-card-title {
    font-size: 18px;
    font-weight: 700;
    color: #1C222B;
  }
}

.task-add-doc-menu {
  .ant-dropdown-menu-item {
    padding: 0 !important;
  }

  .ant-upload-select {
    width: 100%;
  }

  .ant-btn {
    text-align: left;
  }

}

.sign-doc-drawer {
  .ant-drawer-content-wrapper {
    height: calc(100vh - 120px) !important;
  }
}

.ant-tabs-nav-list {
  padding-inline-start: 0 !important;
}

.notification-dropdown {
  .ant-dropdown-menu-item {
    align-items: start !important;
  }
}

.hightlighten-glowing {
  // animation: glow 0.5s infinite alternate;
  animation-name: glow;
  animation-duration: 0.5s;
  animation-iteration-count: 5;
}

@keyframes glow {
  from {
    box-shadow: 0 0 2px -2px #F7BA1E;
  }
  to {
    box-shadow: 0 0 2px 2px #F7BA1E;
  }
}

.zwf-notification {
  .ant-drawer-content {
    background-color: transparent;
  }

  .ant-drawer-content-wrapper {
    box-shadow: none;
  }

  .ant-drawer-header {
    // display: none;
    border: none;
    padding-left: 8px;

    .ant-drawer-close {
      color: white;
      font-size: 32px;
    }
  }

  .ant-list-item > .ant-card {
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }
}

.control-instruction-item {
  .ant-form-item-optional {
    display: none !important;
  }
}